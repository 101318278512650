// @flow

import type { Node } from 'react'
import React from 'react'
import { map } from 'ramda'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

import type { ContentfulPageImageNode } from '../../types'
import Layout from '../../components/layout'
import { MediaLink } from '../../components/links'
import { Heading1, BodyText } from '../../components/typography'
import ContactForm from '../contact'
import LocationMap from '../location-map'

type SeoPageTemplateProps = {
  title: string,
}

type ImageNode = {
  id: string,
  title: string,
  description: string,
  image: ContentfulPageImageNode,
}

type QueryResponse = {|
  genericSEOPage: {
    images: ImageNode[],
  },
|}

type Image = {
  id: string,
  image: {
    src: string,
    alt: string,
  },
}

function SeoPageTemplate({ title }: SeoPageTemplateProps): Node {
  const data: QueryResponse = useStaticQuery(graphql`
    query GenericSeoPageQuery {
      genericSEOPage: contentfulPage(slug: { eq: "GenericSEOPage" }) {
        images {
          id
          title
          description
          image: fixed(width: 1800, quality: 60) {
            src
          }
        }
      }
    }
  `)

  const images: Image[] = map(
    ({ id, description, image: { src } }) => ({
      id,
      image: { src, alt: description },
    }),
    data.genericSEOPage.images,
  )

  const renderImages = map(
    ({ id, image }) => (
      <div key={id} className="px-2">
        <img src={image.src} alt={image.alt || ''} />
      </div>
    ),
    images,
  )

  return (
    <Layout>
      <Helmet>
        <title>Cottages of Paradise Point &mdash; {title}</title>
        <meta name="description" content={title} />
      </Helmet>
      <LocationMap isMarkerShown />
      <div className="py-12 md:py-16 px-8">
        <div className="w-full max-w-xl mx-auto">
          <div className="mb-6 md:max-w-md md:mx-auto">
            <Heading1 className="mb-4" isTitle>
              {title}
            </Heading1>
            <article className="leading-normal">
              <div className="mb-12">
                <BodyText className="mb-5">
                  Find your perfect vacation home rental at Cottages of Paradise
                  Point. We are cradled in the balmy town of{' '}
                  <MediaLink href="https://cottagesofparadisepoint.us17.list-manage.com/pages/track/click?u=668e222996bad33b84db208df&id=834f712065">
                    Fort Myers Beach, Florida
                  </MediaLink>
                  , Cottages of Paradise Point makes a perfect vacation home
                  getaway.
                </BodyText>
                <BodyText className="mb-5">
                  <MediaLink href="https://cottagesofparadisepoint.us17.list-manage.com/pages/track/click?u=668e222996bad33b84db208df&id=9a75485885">
                    Cottages of Paradise Point
                  </MediaLink>{' '}
                  is a lovely little getaway on the much desired tranquil north
                  end of Ft. Myers Beach. It is quiet enough to get some peace
                  and yet brimming with local life. You will be sure to find a
                  perfect balance here of relaxation and fun in the sun!
                </BodyText>
                <BodyText className="mb-5">
                  Here at Cottages of Paradise Point one never runs out of
                  things to do&mdash;the beach, the shopping, and the
                  night-life. We like to think that we offer our guests{' '}
                  <MediaLink href="https://cottagesofparadisepoint.us17.list-manage.com/pages/track/click?u=668e222996bad33b84db208df&id=9e0dc715bd">
                    indulgences
                  </MediaLink>
                  , special touches and fine details that the competition just
                  doesn’t think about. We love to go that extra mile so you feel
                  like you are at home.
                </BodyText>
                <BodyText className="mb-5">
                  So{' '}
                  <MediaLink href="https://cottagesofparadisepoint.us17.list-manage.com/pages/track/click?u=668e222996bad33b84db208df&id=2b0c942e7f">
                    book your vacation
                  </MediaLink>{' '}
                  at Cottages of Paradise Point today; where you’ll find solace
                  in our relaxing Fort Myers Beach Cottages.
                </BodyText>
              </div>
              {images.length > 0 ? (
                <div className="flex -mx-2">{renderImages}</div>
              ) : null}
            </article>
          </div>
        </div>
      </div>
      <div className="bg-purple text-purple-lightest py-12 px-8">
        <div className="max-w-md mx-auto">
          <Heading1 className="text-white">
            Contact Us for Booking Details and Reservations
          </Heading1>
          <ContactForm formName="contact" />
        </div>
      </div>
    </Layout>
  )
}

export default SeoPageTemplate
