// @flow

import type { Node } from 'react'
import React from 'react'
import SeoPageTemplate from '../scenes/seo-pages'

function SeoFtMyersBeachCottageRentals(): Node {
  return <SeoPageTemplate title="Ft. Myers Beach Cottage Rentals" />
}

export default SeoFtMyersBeachCottageRentals
